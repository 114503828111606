import { MoreOutlined } from '@ant-design/icons';
import { Dropdown as AntDropdown } from 'antd';
import isNaN from 'lodash/isNaN';
import React, { useState, useEffect } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import IDS from 'shared/constants/IDS';
import preventClick from 'shared/helpers/preventClick';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import ArrowDownIcon from '../../../static/icons/arrow-down.svg';
import { layoutScrollbar } from '../../app/styles/scrollbar';
import DrawerModal from '../Modals/DrawerModal';
import Portal from '../Portal';
import SplittedNumber from '../SplittedNumber';
import DropdownItem from './DropdownItem';

const Overlay = styled.div`
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.8);
`;

const DrawerContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-height: 100%;
  overflow: hidden;
  padding: 0;
  border-radius: 8px 8px 0 0;
  padding-bottom: 8px;
  box-shadow: 0px -8px 24px rgba(0, 0, 0, 0.12);
  background-color: #fff;
`;

const StyledContainer = styled.div`
  .ant-dropdown-menu-item-danger:hover {
    background: rgba(0, 0, 0, 0.04) !important;
    color: #eb5757 !important;
  }
`;

interface SubmenuGlobalStylesProps {
  maxHeight?: string;
  isSmall?: boolean;
  withNested?: boolean;
}

const SubmenuGlobalStyles = createGlobalStyle<SubmenuGlobalStylesProps>`
  .ant-dropdown-menu {
    border-radius: 8px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
    max-height: ${({ maxHeight }) => maxHeight};
    overflow-y: auto;
    overflow-x: hidden;
    ${layoutScrollbar}
    

    ${({ isSmall }) =>
      isSmall &&
      css`
        padding: 8px;

        & > li:not(:last-child) {
          margin-bottom: 8px;
        }
      `}
  }

  .ant-dropdown-trigger:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

interface StyledDropdownButtonProps {
  isLarge?: boolean;
  transparentBlue?: boolean;
  open?: boolean;
}

const StyledDropdownButton = styled.button<StyledDropdownButtonProps>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${({ isLarge }) => (isLarge ? '40px' : '32px')};
  min-width: ${({ isLarge }) => (isLarge ? '40px' : '32px')};
  margin: 0;
  padding: 6px 8px;
  outline: none;
  border: 0;
  border-radius: 8px;
  background: transparent;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: #f5f5f5;
    cursor: pointer;

    svg {
      opacity: 1;
    }

    /* transparentBlue */
    background-color: ${({ transparentBlue }) => transparentBlue && 'rgba(47, 128, 237, 0.08)'};
  }

  &:active {
    /* transparentBlue */
    background-color: ${({ transparentBlue }) => (transparentBlue ? 'rgba(47, 128, 237, 0.08)' : '#E1E1E1')};
  }

  ${({ open }) =>
    open &&
    css`
      background-color: #e1e1e1;

      &:hover {
        background-color: #e1e1e1;
      }
    `}
`;

interface StyledDropdownIndicatorProps {
  open?: boolean;
  transparentBlue?: boolean;
}

const StyledDropdownIndicator = styled.div<StyledDropdownIndicatorProps>`
  margin-left: 8px;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;

  & > svg {
    transition: all 0.2s ease-in-out;
    transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0)')};
    opacity: ${({ open }) => (open ? '1' : '0.4')};

    /* transparentBlue */
    fill: ${({ transparentBlue }) => transparentBlue && '#2F80ED'};
  }
`;

interface SubItem {
  label: string;
  value: any;
}

interface Option {
  label: string;
  value: any;
  subMenu?: SubItem[];
  selenium?: string;
}

interface DropdownProps {
  onClick?: (key: string | number, data: any, event: any) => void;
  readonly?: boolean;
  disabled?: boolean;
  options: Option[];
  data?: any;
  placement?: 'bottomLeft' | 'topLeft' | 'topCenter' | 'topRight' | 'bottomCenter' | 'bottomRight';
  children?: any;
  withIndicator?: boolean;
  isSmall?: boolean;
  isLarge?: boolean;
  readonlyLabel?: string;
  transparentBlue?: boolean;
  getPopupContainer?: (node: Element) => HTMLElement;
  withNested?: boolean;
  maxHeight?: string;
  isMobile?: boolean;
  dataSelenium?: string;
}

const Dropdown = (props: DropdownProps) => {
  const {
    options,
    onClick = () => {},
    data = null,
    placement = 'bottomLeft',
    readonly = false,
    children = null,
    withIndicator = false,
    isSmall = false,
    isLarge = false,
    getPopupContainer = null,
    transparentBlue = false,
    disabled = false,
    readonlyLabel = 'Выбрано: ',
    withNested = false,
    maxHeight = null,
    isMobile = false,
    dataSelenium = null,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onVisibleChange = (visible: boolean) => setIsOpen(visible);

  const hideDropdown = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(false);
  };

  const showDropdown = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(true);
  };

  useEffect(() => {
    const appLayout = document.getElementById(IDS.APP_LAYOUT);
    if (appLayout) appLayout.addEventListener('scroll', hideDropdown);

    return () => {
      if (appLayout) {
        appLayout.removeEventListener('scroll', hideDropdown);
      }
    };
  }, []);

  const handleClick = ({ key, domEvent }: { key: string; domEvent: React.MouseEvent<HTMLDivElement> }) => {
    const parsedKey = isNaN(+key) ? key : parseInt(key, 10);

    onClick(parsedKey, data, domEvent);
    setIsOpen(false);
  };

  const buildMenuItems = (options, isSmall) => {
    return options.map((item) => {
      if (item.subMenu) {
        return {
          key: item.value || item.label,
          label: item.label,
          children: buildMenuItems(item.subMenu, isSmall),
        };
      }

      return {
        key: item.value,
        label: <DropdownItem key={item.value} item={item} isSmall={isSmall} selenium={item.selenium} />,
        disabled: item.disabled || false,
        onClick: (e) => handleClick({ key: item.value, domEvent: e.domEvent }),
      };
    });
  };

  const items = buildMenuItems(options, isSmall);

  const Child = children || <MoreOutlined />;
  const className = readonly ? 'dropdown--readonly' : '';

  return isMobile ? (
    <>
      <StyledDropdownButton
        onClick={showDropdown}
        isLarge={isLarge}
        type="button"
        transparentBlue={transparentBlue}
      >
        {readonly ? (
          <>
            {readonlyLabel}
            <SplittedNumber>{options.length}</SplittedNumber>
          </>
        ) : (
          Child
        )}
        {withIndicator && (
          <StyledDropdownIndicator open={isOpen} transparentBlue={transparentBlue}>
            <ArrowDownIcon />
          </StyledDropdownIndicator>
        )}
      </StyledDropdownButton>
      {isOpen && (
        <Portal selector="body">
          <Overlay onClick={hideDropdown}>
            <DrawerContainer>
              <DrawerModal
                closeModal={hideDropdown}
                data={{
                  modalData: {
                    withoutCloseButton: true,
                    title: 'Выберите действие',
                    options,
                    optionClick: onClick,
                    optionData: data,
                  },
                }}
              />
            </DrawerContainer>
          </Overlay>
        </Portal>
      )}
    </>
  ) : (
    <StyledContainer onClick={preventClick} data-selenium={dataSelenium}>
      <SubmenuGlobalStyles maxHeight={maxHeight} isSmall={isSmall} withNested={withNested} />
      <AntDropdown
        open={isOpen}
        overlayClassName={className}
        menu={{ items }}
        trigger={['click']}
        onOpenChange={onVisibleChange}
        placement={placement}
        // @ts-ignore
        getPopupContainer={(trigger) => getPopupContainer || trigger.parentNode}
        disabled={disabled}
      >
        <StyledDropdownButton open={isOpen} isLarge={isLarge} type="button" transparentBlue={transparentBlue}>
          {readonly ? (
            <>
              {readonlyLabel}
              <SplittedNumber>{options.length}</SplittedNumber>
            </>
          ) : (
            Child
          )}
          {withIndicator && (
            <StyledDropdownIndicator open={isOpen} transparentBlue={transparentBlue}>
              <ArrowDownIcon />
            </StyledDropdownIndicator>
          )}
        </StyledDropdownButton>
      </AntDropdown>
    </StyledContainer>
  );
};

export default withCheckIsMobile(Dropdown);
